/*
 *   @name Uludağ Bilişim - PORTAL
 *   @author sinangulsever@gmail.com
 *   @version 1.0
 */


//loadingShow = function () {
//    $(".loading").css("display", "block");
//}
//loadingHide = function () {
//    $(".loading").css("display", "none");
//}

$(document).on("hidden.bs.modal", function (e) {
    $(e.target).removeData("bs.modal").find(".modal-content").empty();
});

//
//$(document).ajaxSend(function (event, request, settings) {
//    loadingShow();
//});
//
//$(document).ajaxComplete(function (event, request, settings) {
//    loadingHide();
//});

$(document).on("click", ".selectabled tbody tr", function () {
    $(".selectabled tbody tr").each(function () {
        $(this).removeClass("selection");
    });
    $(this).addClass("selection");
});

$(document).ready(function () {

    $('.tooltips').tooltip();


    // Add body-small class if window less than 768px
    if ($(this).width() < 769) {
        $('body').addClass('body-small')
    } else {
        $('body').removeClass('body-small')
    }

    // MetsiMenu
    $('#side-menu').metisMenu();

    // Collapse ibox function
    $('.collapse-link').on('click', function () {
        var ibox = $(this).closest('div.ibox');
        var button = $(this).find('i');
        var content = ibox.children('.ibox-content');
        content.slideToggle(200);
        button.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down');
        ibox.toggleClass('').toggleClass('border-bottom');
        setTimeout(function () {
            ibox.resize();
            ibox.find('[id^=map-]').resize();
        }, 50);
    });

    // Close ibox function
    $('.close-link').on('click', function () {
        var content = $(this).closest('div.ibox');
        content.remove();
    });

    // Fullscreen ibox function
    $('.fullscreen-link').on('click', function () {
        var ibox = $(this).closest('div.ibox');
        var button = $(this).find('i');
        $('body').toggleClass('fullscreen-ibox-mode');
        button.toggleClass('fa-expand').toggleClass('fa-compress');
        ibox.toggleClass('fullscreen');
        setTimeout(function () {
            $(window).trigger('resize');
        }, 100);
    });

    // Minimalize menu
    $('.navbar-minimalize').on('click', function () {
        $("body").toggleClass("mini-navbar");
        SmoothlyMenu();

    });

    // Full height of sidebar
    function fix_height() {
        var heightWithoutNavbar = $("body > #wrapper").height() - 61;
        $(".sidebard-panel").css("min-height", heightWithoutNavbar + "px");

        var navbarHeight = $('nav.navbar-default').height();
        var wrapperHeigh = $('#page-wrapper').height();

        if (navbarHeight > wrapperHeigh) {
            $('#page-wrapper').css("min-height", navbarHeight + "px");
        }

        if (navbarHeight < wrapperHeigh) {
            $('#page-wrapper').css("min-height", $(window).height() + "px");
        }

        if ($('body').hasClass('fixed-nav')) {
            if (navbarHeight > wrapperHeigh) {
                $('#page-wrapper').css("min-height", navbarHeight + "px");
            } else {
                $('#page-wrapper').css("min-height", $(window).height() - 60 + "px");
            }
        }

    }

    fix_height();

    // Fixed Sidebar
    $(window).bind("load", function () {
        if ($("body").hasClass('fixed-sidebar')) {
            $('.sidebar-collapse').slimScroll({
                height: '100%',
                railOpacity: 0.9
            });
        }
    });

    $(window).bind("load resize scroll", function () {
        if (!$("body").hasClass('body-small')) {
            fix_height();
        }
    });

    // Add slimscroll to element
    $('.full-height-scroll').slimscroll({
        height: '100%'
    })
});


// Minimalize menu when screen is less than 768px
$(window).bind("resize", function () {
    if ($(this).width() < 769) {
        $('body').addClass('body-small')
    } else {
        $('body').removeClass('body-small')
    }
});

function SmoothlyMenu() {
    if (!$('body').hasClass('mini-navbar') || $('body').hasClass('body-small')) {
        // Hide menu in order to smoothly turn on when maximize menu
        $('#side-menu').hide();
        // For smoothly turn on menu
        setTimeout(
                function () {
                    $('#side-menu').fadeIn(400);
                }, 200);
    } else if ($('body').hasClass('fixed-sidebar')) {
        $('#side-menu').hide();
        setTimeout(
                function () {
                    $('#side-menu').fadeIn(400);
                }, 100);
    } else {
        // Remove all inline style from jquery fadeIn function to reset menu state
        $('#side-menu').removeAttr('style');
    }
}

function tckimlikkontorolu(tcno) {
    var tckontrol, toplam;
    tckontrol = tcno;
    tcno = tcno.value;
    toplam = Number(tcno.substring(0, 1)) + Number(tcno.substring(1, 2)) +
            Number(tcno.substring(2, 3)) + Number(tcno.substring(3, 4)) +
            Number(tcno.substring(4, 5)) + Number(tcno.substring(5, 6)) +
            Number(tcno.substring(6, 7)) + Number(tcno.substring(7, 8)) +
            Number(tcno.substring(8, 9)) + Number(tcno.substring(9, 10));
    strtoplam = String(toplam);
    onunbirlerbas = strtoplam.substring(strtoplam.length, strtoplam.length - 1);

    if (onunbirlerbas == tcno.substring(10, 11)) {
        alert("doğru");
    } else {
        alert("yanlış");
    }
}


$.extend($.validator.messages, {
    required: "Bu alanın doldurulması zorunludur.",
    remote: "Lütfen bu alanı düzeltin.",
    email: "Lütfen geçerli bir e-posta adresi giriniz.",
    url: "Lütfen geçerli bir web adresi (URL) giriniz.",
    date: "Lütfen geçerli bir tarih giriniz.",
    dateISO: "Lütfen geçerli bir tarih giriniz(ISO formatında)",
    number: "Lütfen geçerli bir sayı giriniz.",
    digits: "Lütfen sadece sayısal karakterler giriniz.",
    creditcard: "Lütfen geçerli bir kredi kartı giriniz.",
    equalTo: "Lütfen aynı değeri tekrar giriniz.",
    extension: "Lütfen geçerli uzantıya sahip bir değer giriniz.",
    maxlength: $.validator.format("Lütfen en fazla {0} karakter uzunluğunda bir değer giriniz."),
    minlength: $.validator.format("Lütfen en az {0} karakter uzunluğunda bir değer giriniz."),
    rangelength: $.validator.format("Lütfen en az {0} ve en fazla {1} uzunluğunda bir değer giriniz."),
    range: $.validator.format("Lütfen {0} ile {1} arasında bir değer giriniz."),
    max: $.validator.format("Lütfen {0} değerine eşit ya da daha küçük bir değer giriniz."),
    min: $.validator.format("Lütfen {0} değerine eşit ya da daha büyük bir değer giriniz."),
    require_from_group: $.validator.format("Lütfen bu alanların en az {0} tanesini doldurunuz.")
});


/*! Select2 4.0.5 | https://github.com/select2/select2/blob/master/LICENSE.md */




!(function (window, document, undefined) {
    if (window.jQuery && window.jQuery.validator) {
        window.jQuery.validator.addMethod('tcno', tcKimlikNoKontrol);
    }
    function tcKimlikNoKontrol(value) {
        value += '';
        var odd = even = total = 0;
        if (/^[1-9][0-9]{10}$/.test(value)) {
            var invalid = ['/11111111110/', '/22222222220/', '/33333333330/', '/44444444440/', '/55555555550/', '/66666666660/', '/7777777770/', '/88888888880/', '/99999999990/'];
            if (new RegExp('\\b' + value + '\\b').test(invalid)) {
                return false;
            }
            for (var i = 0; i < 10; i++) {
                total += (value[i] * 1);
                if (i < 9) {
                    if (i % 2) {
                        odd += (value[i] * 1);
                    } else {
                        even += (value[i] * 1);
                    }
                }
            }
            return (((even * 7 - odd) % 10 == value[9]) && (total % 10 == value[10]));
        }
        return false;
    }
    return window['tcKimlikNumarasiKontrol'] = tcKimlikNoKontrol;
})(window, document);